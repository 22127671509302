export const PACKAGE_SLOT_COLOR_CODE = {
  MISSED: 'missed',
  FUTURE: 'future',
};

export const RETURN_TYPE = {
  FRANCHISE: 'FRANCHISE',
  INTERFC: 'INTERFC',
};

export const ShipPkgDeliveryStatusCNST = {
  CREATED: 'created',
  ASSIGNED: 'verified_by_ops',
  TO_DELIVER: 'verified',
  HOLD: 'hold',
  DELIVERED: 'delivered',
  RETURN_REQUESTED: 'return_requested',
  RETURNED: 'process_for_return',
  ARCHIVE: 'archive',
  DISPATCHED: 'in_transit',
  MISSING: 'missing',
  MISSING_IN_RETURNS: 'missing_in_returns',
  MISSED_BY_LP_INWARD: 'misplaced_by_lp',
  MISSED_BY_LP_RETURNS: 'misplaced_in_returns_by_lp',
  RETURNED_TO_WAREHOUSE: 'returned',
  RETURNED_IN_RETURNFC: 'returned_in_returnfc',
  RETURN_IN_TRANSIT: 'return_in_transit',
  INTERFC_IN_TRANSIT: 'interfc_in_transit',
  MANIFEST_CREATED: 'package_manifest_created',
  CANCELLED: 'cancelled',
  RETURN_MANIFEST_CREATED: 'return_manifest_created',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  COULD_NOT_DELIVER: 'could_not_deliver',
  FAILED_TO_DELIVER: 'failed_to_deliver',
  ASSIGNED_TO_DELIVERY_BOY: 'assigned_delivery_boy',
  RECEIVED : "received",
  INTERFC_MANIFEST_CREATED : "interfc_manifest_created",
  INTERFC_PACKAGE_IN_TRANSIT : "interfc_package_in_transit",
  INTERFC_PACKAGE_MANIFEST_CREATED : "interfc_package_manifest_created",
  RETURNED_IN_LAYOVERFC : "returned_in_layoverfc",
  PICKUP_SCHEDULED : 'pickup_scheduled',
  PICKUP_RESCHEDULED : 'pickup_rescheduled',
};

const STATUS_COLORS = {
  GREEN: '#47a165',
  YELLOW: '#f5a623',
  RED: '#d0021b',
};

export const ShipPkgDeliveryStatusColor = {
  [ShipPkgDeliveryStatusCNST.CREATED]: STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.ASSIGNED]: STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.TO_DELIVER]: STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.HOLD]: STATUS_COLORS.YELLOW,
  [ShipPkgDeliveryStatusCNST.DELIVERED]: STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.RETURN_REQUESTED]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.RETURNED]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.ARCHIVE]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.DISPATCHED]: STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.MISSING]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.MISSING_IN_RETURNS]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.MISSED_BY_LP_INWARD]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.MISSED_BY_LP_RETURNS]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.RETURNED_TO_WAREHOUSE]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.RETURNED_IN_RETURNFC]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.RETURN_IN_TRANSIT]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.INTERFC_IN_TRANSIT]: STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.MANIFEST_CREATED]: STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.CANCELLED]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.RETURN_MANIFEST_CREATED]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.OUT_FOR_DELIVERY]: STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.COULD_NOT_DELIVER]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.FAILED_TO_DELIVER]: STATUS_COLORS.RED,
  [ShipPkgDeliveryStatusCNST.ASSIGNED_TO_DELIVERY_BOY]: STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.RECEIVED] : STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.INTERFC_MANIFEST_CREATED] : STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.INTERFC_PACKAGE_IN_TRANSIT] : STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.INTERFC_PACKAGE_MANIFEST_CREATED] : STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.RETURNED_IN_LAYOVERFC] : STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.PICKUP_SCHEDULED] : STATUS_COLORS.GREEN,
  [ShipPkgDeliveryStatusCNST.PICKUP_RESCHEDULED] : STATUS_COLORS.GREEN,
};

export const ShippingPackageStatusCNST = {
  TO_BE_PAID: 'to_be_paid',
  WAITING_FOR_CONFIRMATION: 'to_be_verified',
  RECONCILED: 'reconciliation_done',
  ASSIGNED: 'assigned',
};

export const CANCELLED_STATUS = {
  ALL: 'all',
  PARTIAL: 'partial',
};

export const UNDO_BLOCKED_STATUS = [
  ShipPkgDeliveryStatusCNST.CREATED,
  ShipPkgDeliveryStatusCNST.HOLD,
  ShipPkgDeliveryStatusCNST.RETURN_REQUESTED,
  ShipPkgDeliveryStatusCNST.CANCELLED,
  ShipPkgDeliveryStatusCNST.MISSING,
  ShipPkgDeliveryStatusCNST.MISSING_IN_RETURNS,
  ShipPkgDeliveryStatusCNST.MISSED_BY_LP_INWARD,
  ShipPkgDeliveryStatusCNST.MISSED_BY_LP_RETURNS,
  ShipPkgDeliveryStatusCNST.FAILED_TO_DELIVER,
  ShipPkgDeliveryStatusCNST.MANIFEST_CREATED,
  ShipPkgDeliveryStatusCNST.ASSIGNED_TO_DELIVERY_BOY,
];

export const ARCHIVE_STATUS = [
  ShipPkgDeliveryStatusCNST.CREATED,
  ShipPkgDeliveryStatusCNST.ASSIGNED,
];

export const EKART_GROUPS = ['eKart-Large', 'eKart-Non-Large'];
